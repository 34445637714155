import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '../components/Link';
import BarApp from '../components/BarApp';
import Footer from '../components/Footer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';
import UsbIcon from '@material-ui/icons/Usb';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SEO from '../components/Seo';
import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles((theme) => ({
  divInicio: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.9,
    minHeight: 500,
    backgroundImage: 'url(/static/images/background/desarrollo-e-integracion.jpg)',
    backgroundColor: 'transparent',
    transition: 'opacity 0.5s ease 500ms',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    zIndex: 3

  },
  curba: {
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: 1,
    lineHeight: 0,
    bottom: 0,
  },
  svgCurba: {
    fill: 'rgb(248, 248, 248)',
    height: '80px',
    width: '100%',
  },
  titulo: {
    paddingBottom: 30,
    color: 'white'
  },
  tipoSubTitulo: {
    fontSize: 40,
    color: 'white',
    lineHeight: 1.09,
    fontWeight: 100
  },
  tipoTitulo: {
    fontSize: 50,
    color: 'white',
    lineHeight: 1.09,
    fontWeight: 700
  },
  botones: {
    margin: 20,
    padding: 15
  },
  section: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'rgb(248, 248, 248)'
  },
  sectionWhite: {
    paddingTop: 70,
    paddingBottom: 40,
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20
  },
  sectionMin: {
    paddingTop: 50,
    paddingBottom: 30,
    backgroundColor: '#EC2E5D',
    color: 'white'
  },
  servicioVerde: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#26BEC9',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  servicioRosa: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#EC2E5D',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 160,
  },
  textCard: {
    fontSize: 17
  },
  textoExperiencia: {
    fontSize: '12px',
    lineHeight: '2em',
    letterSpacing: '2px',
    color: 'rgb(171, 171, 171)',
    display: 'flex',
    alignItems: 'flex-end',
    textTransform: 'uppercase',
  },
  years: {
    fontSize: '120px',
    lineHeight: 0.8,
    fontWeight: 900,
    color: '#EC2E5D',
    margin: '0px 5px -5px -5px',
  },
  subTextoExperiencia: {
    fontSize: '20px',
    color: 'rgb(51, 51, 51)',
  },
  quantity: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 1,
    color: 'white',
    marginBottom: '5px',
  },
  quantityText: {
    marginBottom: '19px',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  nuestraEmpresa: {
    color: 'rgb(171, 171, 171)',
    fontWeight: 400,
    marginTop: '-5px',
    marginBottom: '20px',
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '3px',
    lineHeight: 1.43,
  },
  sectionTipoProduct: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'transparent',
    marginTop: -130,
    zIndex: 3,
    display: 'block',
    padding: 40,
  },
  paperProductVerde: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    padding: 15,
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#26BEC9',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  paperProductRosa: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    padding: 15,
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#EC2E5D',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  icono: {
    fontSize: 80
  },
  divContacto: {
    color: 'white',
    paddingTop: 80,
    paddingBottom: 80,
    backgroundPosition: '80% 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundColor: '#006f77',
    backgroundImage: 'url(static/images/mapa.png)',
  },
  bloqueTexto: {
    borderStyle: 'none none none solid',
    padding: 15,
    borderColor: 'white',
  },
  valores: {
    marginTop: '30px',
    padding: '36px 10px 26px',
    borderStyle: 'solid',
    borderColor: 'rgb(238, 238, 238)',
    borderWidth: '2px',
    borderImage: 'initial',
    transition: 'all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    '&:hover': {
      transform: 'translateY(-5px)',
      borderWidth: '0px',
      boxShadow: 'rgba(51, 51, 51, 0.1) 0px 0px 40px',
    }
  }
}));


export default function AsesoriaLegar(props) {

  const classes = useStyles();

  return (
    <React.Fragment>


      <SEO
        title={'Desarollo e integración Organizacional | Velvet Consultores '}
        description={'Estamos comprometidos con el desarrollo del talento humano que gestionan nuestros clientes.'}
        image={'/static/images/background/desarrollo-e-integracion.jpg'}
        siteUrl={'https://corporacionvelvet.com'}
        pathname={'desarrollo-e-integracion-organizacional'}
        article
      />
      <CssBaseline />
      <BarApp />
      <main className={classes.main} style={{ backgroundColor: '#F8F8F8' }}>
        <div className={classes.divInicio}>
          <Container maxWidth='sm'>
            <div className={classes.titulo} style={{ marginTop: 60 }}>
              <Typography align='center' variant='h3' className={classes.tipoTitulo} style={{ fontSize: 45 }}>
                DESARROLLO
              </Typography>
              <Typography align='center' variant='h3' className={classes.tipoTitulo} style={{ fontSize: 35 }}>
                &
              </Typography>
              <Typography align='center' variant='h3' className={classes.tipoTitulo} style={{ fontSize: 45 }} >
                INTEGRACIÓN
              </Typography>
              <br />
              <Typography align='center' variant='h3' className={classes.tipoTitulo} style={{ fontSize: 45 }}>
                ORGANIZACIONAL
              </Typography>
            </div>
            <div align='center'>
              <a href='tel:961970025' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button variant='contained' className={classes.botones} size='large' color='secondary'>
                  Consulta Gratis
              </Button>
              </a>
            </div>

          </Container>
          <div className={classes.curba}>
            <svg className={classes.svgCurba} xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
              <path d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
            </svg>
          </div>
        </div>

        <div className={classes.section} style={{ marginTop: 20, marginBottom: 20 }}>
          <Container maxWidth='sm'>
            <Typography align='center' className={classes.nuestraEmpresa}>
              Desarrollo y Integración Organizacional
            </Typography>
            <Typography variant='h5' align='center' style={{ lineHeight: 2 }}>
              Estamos comprometidos con el desarrollo del talento
              humano que gestionan nuestros clientes, por ello
              ejecutamos acciones de consultoría, desarrollo
              e integración del personal.
            </Typography>
            <br />
          </Container>
          <br />
          <br />
          <Container maxWidth='sm'>
            <Typography variant='h5' align='center'>
              ESTAMOS ESPECIALIZADOS EN:
            </Typography>
            <br />
            <br />
          </Container>
          <Container maxWidth='md'>
            <Grid container direction='row' justify='left' alignItems='center' spacing={4}>
              <Grid item xs={12} sm={6} >
                <ListItem button>
                  <ListItemIcon>
                    <CheckOutlinedIcon style={{ marginRight: 10, fontSize: 35, color: '#91C93D' }} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component='span'>Evaluación y programa de clima laboral.</Typography>} />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6} >
                <ListItem button>
                  <ListItemIcon>
                    <CheckOutlinedIcon style={{ marginRight: 10, fontSize: 35, color: '#91C93D' }} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component='span'>Evaluación de desempeño.</Typography>} />
                </ListItem>

              </Grid>
              <Grid item xs={12} sm={6} >
                <ListItem button>
                  <ListItemIcon>
                    <CheckOutlinedIcon style={{ marginRight: 10, fontSize: 35, color: '#91C93D' }} />
                  </ListItemIcon>

                  <ListItemText primary={<Typography component='span'>Evaluación y plan de control de Riesgos Psicosociales.</Typography>} />
                </ListItem>

              </Grid>
              <Grid item xs={12} sm={6} >
                <ListItem button>
                  <ListItemIcon>
                    <CheckOutlinedIcon style={{ marginRight: 10, fontSize: 35, color: '#91C93D' }} />
                  </ListItemIcon>

                  <ListItemText primary={<Typography component='span'>Desarrollo de Programa para la Felicidad en el trabajo.</Typography>} />
                </ListItem>

              </Grid>
              <Grid item xs={12} sm={6} >
                <ListItem button>
                  <ListItemIcon>
                    <CheckOutlinedIcon style={{ marginRight: 10, fontSize: 35, color: '#91C93D' }} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component='span'>Talleres de Integración grupales.</Typography>} />
                </ListItem>

              </Grid>
            </Grid>

          </Container>
        </div>
        <div className={classes.sectionMin}>
          <Container maxWidth='lg'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={8}>
              <Grid container direction='row' justify='center' alignItems='center' spacing={2} >
                <Grid item xs={12} sm={6} md={3}>
                  <div align='center'>
                    <Typography align='center' className={classes.quantity}>
                      25
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Empresas felices
                      </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <div>
                    <Typography align='center' className={classes.quantity}>
                      1500
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Personas capacitadas
                      </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div>
                    <Typography align='center' className={classes.quantity}>
                      500
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Procesos exitosos
                      </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div>
                    <Typography align='center' className={classes.quantity}>
                      2500
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Personas reclutadas
                      </Typography>
                  </div>
                </Grid>

              </Grid>

            </Grid>
          </Container>
        </div>

        <div className={classes.divContacto}>
          <Container maxWidth='md'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
              <Grid item xs={12} md={6}>
                <div align='left' className={classes.bloqueTexto}>
                  <Typography variant='h4'>
                    Para obtener más información, póngase en contacto con nuestros expertos.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div align='center'>
                  <a href='tel:961970025' style={{ textDecoration: 'none', color: 'white' }}>
                    <PhoneIcon style={{ fontSize: 50 }} />
                  </a>
                  <br />
                  <Typography variant='h6'>
                    ¡LLAMANOS!
                  </Typography>
                  <a href='tel:936164464' style={{ textDecoration: 'none', color: 'white' }}>
                    <Typography variant='h4' style={{ fontWeight: 700, lineHeight: 1.41, marginTop: 10 }}>
                      (51) 961 970 025
                    </Typography>
                  </a>
                </div>
              </Grid>

            </Grid>
          </Container>

        </div>

      </main>
      <Footer />
    </React.Fragment >
  );
}
